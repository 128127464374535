<template>
  <v-card color="" elevation="0" outlined class="pa-0">
    <div class="card-header">
      <v-card-title class="d-flex justify-space-between pa-5">
        <div class="white--text headline">Ajouter Van</div>
        <v-icon color="white" class="d-none d-sm-flex" @click="toggle()"
          >mdi-close-thick</v-icon
        >
      </v-card-title>
    </div>
    <v-divider />
    <v-card-text style="height: auto">
      <v-card elevation="0">
        <v-card-text class="pt-5 pb-0">
          <v-row no-gutters v-for="item in formText" :key="item.id">
            <v-col cols="12" sm="5">
              <v-subheader class="pa-0 subtitle-1 font-weight-bold">{{
                item.title
              }}</v-subheader>
            </v-col>
            <v-col cols="12" sm="7">
              <v-text-field outlined dense v-model="item.value"></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="5">
              <v-subheader class="pa-0 subtitle-1 font-weight-bold">{{
                "Garage Address"
              }}</v-subheader>
            </v-col>
            <v-col cols="12" sm="7">
              <v-select
                outlined
                :items="garageList"
                item-text="name"
                return-object
                dense
                v-model="garageAddress"
              ></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="5">
              <v-subheader class="pa-0 subtitle-1 font-weight-bold">
                Technical Visit Due Date</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="7">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="technicalDueDate"
                    dense
                    append-icon="mdi-calendar"
                    outlined
                    readonly
                    v-bind="attrs"
                    @blur="techDate = parseDate(technicalDueDate)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="primary"
                  v-model="techDate"
                  @input="dateMenu = false"
                  locale="fr"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="5">
              <v-subheader class="pa-0 subtitle-1 font-weight-bold">
                Insurance Due Date</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="7">
              <v-menu
                v-model="dateMenu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="insuranceDueDate"
                    dense
                    append-icon="mdi-calendar"
                    outlined
                    readonly
                    v-bind="attrs"
                    @blur="insuranceDate = parseDate(insuranceDueDate)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="primary"
                  v-model="insuranceDate"
                  @input="dateMenu2 = false"
                  locale="fr"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="5">
              <v-subheader class="pa-0 subtitle-1 font-weight-bold"
                >Status</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="7">
              <v-select
                outlined
                :items="statusItems"
                dense
                v-model="status"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-row no-gutters justify-sm="space-between">
        <v-col cols="12" sm="auto" order="2" order-sm="1">
          <v-btn
            :block="$vuetify.breakpoint.xs ? true : false"
            color="grey"
            class="mt-5 mt-sm-0"
            elevation="0"
            dark
            @click="toggle()"
            >Cancel
          </v-btn>
        </v-col>
        <v-col cols="12" sm="auto" order="1" order-sm="1">
          <v-btn
            :block="$vuetify.breakpoint.xs ? true : false"
            color="primary"
            elevation="0"
            dark
            @click.once="addNewVan"
          >
            Confirm
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import * as fb from "../../firebase";
import * as dayjs from "dayjs";

export default {
  props: {
    toggle: {
      type: Function,
      required: false,
    },
    updateVanArray: {
      type: Function,
      required: false,
    },
  },

  data: () => ({
    garageList: [],
    dateMenu: false,
    dateMenu2: false,
    techDate: "",
    technicalDueDate: "",
    insuranceDate: "",
    insuranceDueDate: "",
    garageAddress: null,
    statusItems: ["Active", "Disable"],
    status: "Active",
    formText: [
      {
        id: "brand",
        title: "Brand",
        value: "",
      },
      {
        id: "model",
        title: "Model",
        value: "",
      },
      {
        id: "registrationNumber",
        title: "Registration Number",
        value: "",
      },
    ],
    macroPlanning: [
      {
        id: 0,
        day: "dimanche",
        start: "",
        end: "",
        driver: "",
        driverId: "",
      },
      {
        id: 1,
        day: "lundi",
        start: "",
        end: "",
        driver: "",
        driverId: "",
      },
      {
        id: 2,
        day: "mardi",
        start: "",
        end: "",
        driver: "",
        driverId: "",
      },
      {
        id: 3,
        day: "mercredi",
        start: "",
        end: "",
        driver: "",
        driverId: "",
      },
      {
        id: 4,
        day: "jeudi",
        start: "",
        end: "",
        driver: "",
        driverId: "",
      },
      {
        id: 5,
        day: "vendredi",
        start: "",
        end: "",
        driver: "",
        driverId: "",
      },
      {
        id: 6,
        day: "samedi",
        start: "",
        end: "",
        driver: "",
        driverId: "",
      },
    ],
  }),

  watch: {
    techDate(val) {
      this.technicalDueDate = this.formatDate(this.techDate);
    },

    insuranceDate(val) {
      this.insuranceDueDate = this.formatDate(this.insuranceDate);
    },
  },

  created() {
    this.getGarageList();
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("-");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    async getGarageList() {
      let addressList = await this.$store.dispatch("getGarageAddressList");
      this.garageList = addressList ? addressList : [];
    },

    async addNewVan() {
      let macroMap = new Map();

      this.macroPlanning.forEach((item) => {
        macroMap.set(item.id, {
          time: item.start ? `${item.start}-${item.end}` : "",
          driver: item.driver,
          driverId: item.driverId,
        });
      });

      let objectedMap = Object.fromEntries(macroMap);

      let vanObject = {
        customId: `SN-VC-${Math.floor(Math.random() * 1000000)}`,
        brand: "",
        model: "",
        registrationNumber: "",
        garageAddress: {
          name: this.garageAddress.name,
          lat: Number(this.garageAddress.lat),
          lng: Number(this.garageAddress.lng),
        },
        assignedDrivers: [], // array of driver-customId SN-CH-01
        techDueDate: this.technicalDueDate,
        insuranceDueDate: this.insuranceDueDate,
        status: this.status, // Active/Disabled
        lastPosition: {}, // Google maps coordinates
        upcomingTrip: {},
        macroPlanning: objectedMap,
        createdAt: new Date().getTime(),
      };

      this.formText.forEach((data) => {
        if (data.id == "brand") {
          vanObject.brand = data.value;
        } else if (data.id == "model") {
          vanObject.model = data.value;
        } else if (data.id == "registrationNumber") {
          vanObject.registrationNumber = data.value;
        }
      });

      const van = await fb.db.collection("van").add(vanObject);
      // const newVan = await this.$store.dispatch('van/createVan', vanObject)

      vanObject.id = van.id;
      vanObject.brandModel = `${vanObject.brand} ${vanObject.model}`

      this.updateVanArray(vanObject);
      this.toggle();
    },
  },
};
</script>

<style></style>
